import React from 'react'
import PropTypes from 'prop-types'

import './styles/index.sass'

import PreviewBox from '../PreviewBox'
import NoMatch from '../NoMatch'

import budgetIcon from '../../../static/img/budgetIcon.png';
import coinsIcon from '../../../static/img/coinsIcon.png';
import wingsIcon from '../../../static/img/wingsIcon.png';
  
const List = class extends React.Component {

  render(){

    return(
        <div className="list__container">

          <div className="list__priceClass-container">
            <img src={budgetIcon} className="list__priceClass-icon" alt="Budget-ikon" /> = Budget
            <img src={coinsIcon} className="list__priceClass-icon" alt="Mellan-ikon" /> = Mellan
            <img src={wingsIcon} className="list__priceClass-icon" alt="Dyrare-ikon" /> = Dyrt
          </div>

          {/* If search results are empty: show error message in NoMatch component */}
          {this.props.posts.length < 1 ? <NoMatch /> : ''}

          {this.props.posts
            .map(({ node: post }) => { 
              return (
                <PreviewBox 
                  key={post.id} 
                  post={post} 
                  calculateDistance={this.props.calculateDistance} 
                  setView={this.props.setView} 
                />
              )
            })
          }
            
        </div>
    )
  }

}

PreviewBox.propTypes = {
  posts: PropTypes.object.isRequired,
  calculateDistance: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
}

export default List;