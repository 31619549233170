import React from 'react'
import Select from 'react-select'
import './styles/index.sass'


const Dropdown = ({onChange, options, placeholder, positionRight, selectedValue, className, classNamePrefix}) => {
    
    // const dropdownStyles = {
    //     control: (base, state) => ({
    //         ...base,
    //         background: '#FFE236',
    //         // background: '#ffffff',
    //         cursor: 'pointer',
    //         border: state.isFocused ? 'none' : 'none',
    //         outline: state.isFocused ? 'none' : 'none',
    //         fontFamily: 'Roboto',
    //         textTransform: 'uppercase',
    //         width: window.innerWidth < 768 ? 110 : 122,
    //         minHeight: 25,
    //         maxHeight: window.innerWidth < 768 ? 25 : 38,
    //         boxShadow: 'none',
    //         // border: '1px solid red',
    //         display: 'inline-block',
    //         margin: 0,
    //         textAlign: 'left',
    //         marginLeft: window.innerWidth < 768 ? 5 : 10,
    //         "&:focus": {
    //         border: state.isFocused ? 'none' : 'none',
    //         outline: state.isFocused ? 'none' : 'none',
    //         boxShadow: 'none',
    //         }
    //     }),
    //     input: (state) => ({
    //         outline: state.isFocused ? 'none' : 'none',
    //         boxShadow: 'none',
    //         display: 'none',
    //         border: '1px solid red'
    //     }),
    //     valueContainer: () => ({
    //         // border: '1px solid green',
    //         height: 15,
    //         width: window.innerWidth < 768 ? 90 : 90,
    //         display: 'inline-block',
    //         fontFamily: 'Roboto',
    //         fontWeight: 300,
    //         // border: '1px solid purple',
    //         fontSize: window.innerWidth < 768 ? '12px' : '16px',
    //         padding: 0,
    //         margin: 0,
    //         fontSize: window.innerWidth < 768 ? '12px' : '16px',
    //         fontFamily: 'Roboto',
    //         fontWeight: 300,
    //     }),
    //     singleValue: () => ({
    //         margin: 0,
    //         fontSize: window.innerWidth < 768 ? '12px' : '16px',
    //         display: 'inline-block',
    //     }),
    //     container: (base, state) => ({
    //         border: state.isFocused ? 'none' : 'none',
    //         boxShadow: 'none',
    //         height: 'auto',
    //         margin: 0,
    //         maxHeight: window.innerWidth < 768 ? 25 : 38,
    //         verticalAlign: 'top',
    //         display: 'inline-block',
    //         "&:hover": {
    //         border: state.isFocused ? 'none' : 'none',
    //         outline: state.isFocused ? 'none' : 'none',
    //         borderBottom: state.isFocused ? '2px solid black' : 'none',
    //         }
    //     }),
    //     menu: () => ({
    //         // width: 110,
    //         // height: 'auto',
    //         // background: '#ffffff',
    //         // position: 'absolute',
    //         right: positionRight,
    //         zIndex: 100,
    //         border: '1px solid black',
    //         padding: 0
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         width: 110,
    //         borderBottom: '1px dotted grey',
    //         height: 25,
    //         fontFamily: 'Roboto',
    //         fontWeight: 300,
    //         fontSize: window.innerWidth < 768 ? '12px' : '16px',
    //         fontWeight: 'normal',
    //         backgroundColor: state.isSelected ? '#ffffff' : '#ffffff',
    //         color: state.isSelected ? '#000000' : '#000000',
    //         cursor: 'pointer',
    //         textAlign: 'right',
    //         "&:hover": {
    //         // Overwrittes the different states of border
    //             backgroundColor: 'lightgrey'
    //         }
    //     }),
    //     indicatorSeparator: () => ({
    //         width: 0,
    //         margin: 0,
    //         display: 'none',
    //     }),
    //     dropdownIndicator: () => ({
    //         color: 'black',
    //         display: 'inline-block',
    //         margin: 0,
    //     }),
    //     indicatorsContainer: () => ({
    //         display: 'inline-block',
    //         height: 20,
    //         margin: 0,
    //         verticalAlign: 'top'
    //     }),
    //     placeholder: () => ({
    //         fontSize: window.innerWidth < 768 ? '12px' : '16px',
    //         fontFamily: 'Roboto',
    //         fontWeight: 300,
    //         padding: 0,
    //         margin: 0,
    //     }),
    //     singleValue: (provided, state) => {
    //         const opacity = state.isDisabled ? 0.5 : 1;
    //         const transition = 'opacity 300ms';
        
    //         return { ...provided, opacity, transition };
    //     }
    // }

    return (

        <React.Fragment>

            <Select 
                onChange={onChange} 
                options={options} 
                placeholder={placeholder}
                // styles={dropdownStyles}
                // optionSelected={selectedValue ? selectedValue : null}
                value={selectedValue ? { label: selectedValue } : null}
                className={className}
                classNamePrefix={classNamePrefix}
                // menuIsOpen={true}
                isSearchable={false}
            />

      </React.Fragment>
    );
  }

  export default Dropdown;



