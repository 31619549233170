import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './styles/index.sass'
import Tag from '../Tag'

/* Price class icons */
import budgetIcon from '../../../static/img/budgetIcon.png';
import coinsIcon from '../../../static/img/coinsIcon.png';
import wingsIcon from '../../../static/img/wingsIcon.png';
import mapIcon from '../../../static/img/mapIcon4.png';

/* Food category icons */
import unknownIcon from '../../../static/img/unknownIcon.png';
import hamburgerIcon from '../../../static/img/fastFood.png';
import pizzaIcon from '../../../static/img/pizzaIcon.png';
import mexicanFoodIcon from '../../../static/img/tacoIcon.png';
import sushiIcon from '../../../static/img/sushiIcon.png';
import tikkaIcon from '../../../static/img/tikka.png';
import carrotIcon from '../../../static/img/carrotIcon.png';
import bowlIcon from '../../../static/img/bowlIcon.png';
import falafelIcon from '../../../static/img/falafelIcon.png';


const PreviewBox = class extends React.Component {


    getPriceClassIcon = (category) => {
        switch(category) {
          case 'Budget': return budgetIcon
          case 'Mellan': return coinsIcon
          case 'Dyrt': return wingsIcon
          default:
              return ''
        } 
      }

    // dynamicBackgroundColor = (category) => {
    //     switch(category) {
    //         case 'Asiatiskt': return 'blue' 
    //         case 'Husmanskost': return 'red'
    //         case 'Mexikanskt': return 'pink'
    //         case 'Snabbmat': return 'green'
    //         case 'Blandat': return 'lightgreen'
    //         case 'Sushi': return 'teal'
    //         default:
    //             return ''
    //     } 
    // }

    getFoodIcon = (category) => {
        category = category.toLowerCase(); 

        switch(category) {
            case 'asiatiskt': return sushiIcon
            case 'sushi': return sushiIcon
            case 'pizza': return pizzaIcon
            case 'falafel': return falafelIcon
            case 'soup': 
            case 'ramen': 
            case 'pokebowl':
                return bowlIcon
            case 'hamburgare': 
            case 'snabbmat': 
            case 'grill':
                return hamburgerIcon
            case 'husmanskost': return unknownIcon
            case 'mexikanskt': 
            case 'tacos':
                return mexicanFoodIcon
            case 'indiskt': return tikkaIcon
            case 'vegetariskt': return carrotIcon
            case 'blandat': return unknownIcon
            default:
                return unknownIcon
        } 
    }

    render(){

        return (   

            <div className="preview__container">

                <div className="preview__icons-container">
                    <div className="preview__icon preview__distance">
                        <div className="preview__distance__text">
                            {this.props.calculateDistance(this.props.post.frontmatter.position)} m
                        </div>
                    </div>

                    <div className="preview__icon preview__priceClass">
                        <img src={this.getPriceClassIcon(this.props.post.frontmatter.priceClass) } alt="Prisklass-ikon" />
                    </div>

                    <div className="preview__icon preview__category">
                        <img src={this.getFoodIcon(this.props.post.frontmatter.categories[0])} alt="Kategori-ikon" />
                    </div>
                </div>

                <Link className="preview__title" to={this.props.post.fields.slug + '?lat=' + this.props.post.frontmatter.position[0] + '&lng=' + this.props.post.frontmatter.position[1]} >
                    {this.props.post.frontmatter.title}
                </Link>

                <Link className="preview__address" to={'maps/?lat=' + this.props.post.frontmatter.position[0] + '&lng=' + this.props.post.frontmatter.position[1]} >
                    <div className="preview__address"><strong>Adress: </strong> {this.props.post.frontmatter.address} <img className="preview__mapIcon" src={mapIcon} alt="map icon" /> Se på karta</div>
                </Link>

                <div className="preview__serving">
                    <strong>Serverar: </strong> 
                    {this.props.post.frontmatter.categories.map((category, i) => ( 
                        (this.props.post.frontmatter.categories.length === i + 1) ? category : (category + ', ')
                    ))}
                </div>
                
                <Link to={this.props.post.fields.slug}>
                    <div className="preview__image" style={{ backgroundImage: 'url(' + this.props.post.frontmatter.image.publicURL + ')' }} ></div> 
                </Link>

                <p className="preview__body">
                    <Link to={this.props.post.fields.slug}>
                        {this.props.post.frontmatter.description.substr(0, 350)}... 
                    Läs mer
                    </Link>
                </p>

                <ul className="taglist">
                    {this.props.post.frontmatter.tags.map((tag, i) => (
                        <Tag key={i} tag={tag} className="tag__regular" />  
                    ))}
                </ul>

            </div>

        )

    }

}

PreviewBox.propTypes = {
    post: PropTypes.object.isRequired,
    calculateDistance: PropTypes.func.isRequired,
    setView: PropTypes.func.isRequired
}

export default PreviewBox;