import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './styles/index.sass'

import B from '../../../static/img/B.svg'
import logga from '../../../static/img/bazlunch_testlogga2.png'

import FilterField from '../FilterField'




const Navbar = ({
    setView, 
    sortByDistance,
    sortByPriceClass,
    getRandomSlug,
    posts,
    categoryOptions,
    setFilterCategory,
    setFilterPriceClass,
    clearFilter,
    filterCategory,
    filterPriceClass,
    removeCategoryValueFromFilter,
    removePriceClassValueFromFilter
}) => {

// const Navbar = class extends React.Component {

//   componentDidMount() {
//     // Get all "navbar-burger" elements
//    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
//     // Check if there are any navbar burgers
//    if ($navbarBurgers.length > 0) {
 
//      // Add a click event on each of them
//      $navbarBurgers.forEach( el => {
//        el.addEventListener('click', () => {
 
//          // Get the target from the "data-target" attribute
//          const target = el.dataset.target;
//          const $target = document.getElementById(target);
 
//          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
//          el.classList.toggle('is-active');
//          $target.classList.toggle('is-active');
 
//        });
//      });
//    }
//  }
 
//  render() {
    return (
    
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">

        <div className="navbar__logo">
          {/* <img src={B} className="navbar__logo-img" alt="Bazooka logo" /> */}
          <Link to="/" className="navbar__logo-text" title="Logo">
            {/* azlunch */}
            <img src={logga} className="navbar__logo-img2" alt="Bazooka logo" />
          </Link>
        </div>



        <FilterField 
          setView={setView} 
          sortByDistance={sortByDistance} 
          sortByPriceClass={sortByPriceClass}
          getRandomSlug={getRandomSlug}
          posts={posts}
          categoryOptions={categoryOptions}
          setFilterCategory={setFilterCategory}
          setFilterPriceClass={setFilterPriceClass}
          clearFilter={clearFilter}
          filterCategory={filterCategory}
          filterPriceClass={filterPriceClass}
          removeCategoryValueFromFilter={removeCategoryValueFromFilter}
          removePriceClassValueFromFilter={removePriceClassValueFromFilter}
        />

      </nav>
    )
  // }
}

// PreviewBox.propTypes = {
//   posts: PropTypes.object.isRequired,
//   calculateDistance: PropTypes.func.isRequired
// }

export default Navbar
