import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import * as geometry from 'spherical-geometry-js';
import '../styles/index.sass'

import Navbar from '../components/Navbar'
import FilterField from '../components/FilterField'
import List from '../components/List'


export default class IndexPage extends React.Component {

  state = {
    allPosts: [],
    posts: [],
    requestedPosition: {
      lat: 59.318286,
      lng: 18.075884
    },
    filterCategory: '',
    filterPriceClass: ''
  }

  setView = (requestedView, requestedPosition) => {

    /* If there's no requested position, set center to Bazooka-position */
    if(!requestedPosition){
      requestedPosition = [59.318286, 18.075884];
    }

    this.setState({ view: requestedView, requestedPosition: { lat: requestedPosition[0], lng: requestedPosition[1] }})
  }

  sortByDistance = () => {
    let dataToSort = [...this.props.data.allMarkdownRemark.edges]

    for(var i = 0; i < dataToSort.length; i++){
      /* set distance property to distance calculated with calculateDistance fn */
      dataToSort[i].node.frontmatter.distance = this.calculateDistance(dataToSort[i].node.frontmatter.position);
    }

    let sortedData = dataToSort.sort(function(a, b) {
      return a.node.frontmatter.distance - b.node.frontmatter.distance;
    });
    this.setState({ posts: sortedData });
  }

  setFilterCategory = (e) => {
    let searchword = e.label;

    if(!this.state.filterCategory){
      this.setState({ filterCategory: searchword}, () => {
        this.generateFilteredPosts();
      })
    /* If 1+ options are chosen, all are saved in a string and separated with comma */
    }else{
      /* Comment: Look for duplicates here */
      let currentCategoryValues = this.state.filterCategory.split(', ');
      console.log(currentCategoryValues);
      /* Search for index where category allready exists */
      var existingIndex =  currentCategoryValues.indexOf(searchword);
      console.log(existingIndex );

      /* If existingIndex = -1 the category does not exist yet and we can go go setting state: */
      if(existingIndex === -1){
        this.setState({ filterCategory: this.state.filterCategory + ', ' + searchword  }, () => {
          this.generateFilteredPosts();
        })   
      }

      // this.setState({ filterCategory: this.state.filterCategory + ', ' + searchword  }, () => {
      //   this.generateFilteredPosts();
      // })
    }
  }

  setFilterPriceClass = (e) => {
    let searchword = e.label;

    this.setState({ filterPriceClass: searchword}, () => {
      this.generateFilteredPosts();
    })
  }

  generateFilteredPosts = () => {
    let dataToSort = this.state.allPosts;
    // Filters only on category[0]:
    // var sortedData = dataToSort.filter((post) => {
    //   if(this.state.filterCategory && this.state.filterPriceClass){
    //     return ((post.node.frontmatter.categories[0] === this.state.filterCategory) && (post.node.frontmatter.priceClass === this.state.filterPriceClass)); 
    //   }else if(this.state.filterCategory && !this.state.filterPriceClass){
    //     return post.node.frontmatter.categories[0] === this.state.filterCategory;
    //   }else if(!this.state.filterCategory && this.state.filterPriceClass){
    //     return post.node.frontmatter.priceClass === this.state.filterPriceClass;
    //   }
    // });

    /* Make an array of the string of categories kept in filterCateogry.state */
    let categoryArray = this.state.filterCategory.split(", ");


      var sortedData = dataToSort.filter((post) => {
        if(this.state.filterCategory && this.state.filterPriceClass){
          for(let j = 0; j < categoryArray.length; j++){
            for(let i = 0; i < post.node.frontmatter.categories.length; i++){
              if((post.node.frontmatter.categories[i] === categoryArray[j]) &&  (post.node.frontmatter.priceClass === this.state.filterPriceClass)){
                return post
              }
            }
          }
        }else if(this.state.filterCategory && !this.state.filterPriceClass){
          for(let j = 0; j < categoryArray.length; j++){
            for(let i = 0; i < post.node.frontmatter.categories.length; i++){
              if(post.node.frontmatter.categories[i] === categoryArray[j]){
                return post
              }
            }
          }
        }else if(!this.state.filterCategory && this.state.filterPriceClass){
          return post.node.frontmatter.priceClass === this.state.filterPriceClass;
        }else{
          return post
        }

      })

      this.setState({ posts: sortedData }); 


    // Filters on category[0],category[1],category[2] etc
    // var sortedData = dataToSort.filter((post) => {
    //   if(this.state.filterCategory && this.state.filterPriceClass){

    //   for(let i = 0; i < post.node.frontmatter.categories.length; i++){
    //     if((post.node.frontmatter.categories[i] === this.state.filterCategory) && (post.node.frontmatter.priceClass === this.state.filterPriceClass)){
    //       return post;
    //     }
    //   }
      
    //   }else if(this.state.filterCategory && !this.state.filterPriceClass){
    //     for(let i = 0; i < post.node.frontmatter.categories.length; i++){
    //       if(post.node.frontmatter.categories[i] === this.state.filterCategory){
    //         console.log(post.node.frontmatter.categories[i]);
    //         return post;
    //       }
    //     }

    //   }else if(!this.state.filterCategory && this.state.filterPriceClass){
    //     return post.node.frontmatter.priceClass === this.state.filterPriceClass;
    //   }
    // });
    // this.setState({ posts: sortedData });

  }
  


  getRandomSlug = () => {
    let ceil = this.state.posts.length;
    let randomNumber = Math.floor(Math.random() * ceil) + 1;
    
    if(this.state.posts[randomNumber] !== undefined){
      let generatedSlug = this.state.posts[randomNumber].node.fields.slug;
      window.location.href = generatedSlug;
    }
  }

  calculateDistance = (position) => {

    if(position){
      const BazookaPosition = new geometry.LatLng(59.318286,18.075884);

      /* Extract latitude and longitude from the position parameter ( = an array) */
      /* Prefeix + to turn string to number */
      let lat = +position[0];
      let lng = +position[1];
      let foodPosition = new geometry.LatLng(lat,lng); 

      let distance = geometry.computeDistanceBetween(BazookaPosition,foodPosition);
      distance = Math.round(distance);

      return distance;
    }
    return;
  }

  clearFilter = () => {
    this.setState({ filterCategory: '', filterPriceClass: '', posts: [...this.state.allPosts] });
  }

  removeCategoryValueFromFilter = (e) => {

    let categoryToRemove = e.target.dataset.category;
    let categoryString = this.state.filterCategory;

    /* Categories in state (string) to array */
    let arrayFromString = categoryString.split(', ');
    /* Remove array item where it matches incoming dataset.category */
    var index =  arrayFromString.indexOf(categoryToRemove);
    if (index !== -1) arrayFromString.splice(index, 1);
    /* Join to string again */
    let newString = arrayFromString.join(', ');

    this.setState({ filterCategory: newString }, () => {
      this.generateFilteredPosts();
    });
  }

  removePriceClassValueFromFilter = () => {
    this.setState({ filterPriceClass: '' }, () => {
      this.generateFilteredPosts();
    });
  }

  getOptionCategories = () => {
    /* Extract all categories from restaurant posts */
    let allCategoryIndexes = [];

    let array = this.state.allPosts.map(({ node: post }) => { 

      for(let i = 0; i < post.frontmatter.categories.length; i++){
        allCategoryIndexes.push(post.frontmatter.categories[i])
      }

    })

    array = allCategoryIndexes;
    /* Make all category strings to the same format */
    array = this.arrayItemsFirstLetterToUpperCase(array);
    /* Remove duplicates */
    array = this.removeDuplicatesFromArray(array);
    array = this.sortArrayAlphabetically(array);
    /* Make the categories to objects with label keys to suit react-select format */
    array = this.arrayItemsToLabelObjects(array);

    return array;
  }

  sortArrayAlphabetically = (arr) => {
    let sortedArray = arr.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    })
    return sortedArray;
  }


  removeDuplicatesFromArray = (arr) => {
    const unique = arr
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
      
      return unique;
  } 

  arrayItemsFirstLetterToUpperCase = (arr) =>  {
    let returnArray = arr.map(item => {
      return item.charAt(0).toUpperCase() + item.substr(1).toLowerCase();
    })
    return returnArray;
  }

  arrayItemsToLabelObjects = (arr) => {
    let returnArray = arr.map(item => {
      return { label: item }
    })
    return returnArray;
  }


  componentDidMount() {
    this.setState({ posts: this.props.data.allMarkdownRemark.edges, allPosts: this.props.data.allMarkdownRemark.edges });
  }


  render(){

    return (
      <Layout>
        
        <Navbar 
          setView={this.setView} 
          sortByDistance={this.sortByDistance} 
          sortByPriceClass={this.sortByPriceClass}
          getRandomSlug={this.getRandomSlug}
          posts={this.state.posts}
          categoryOptions={this.getOptionCategories()}
          setFilterCategory={this.setFilterCategory}
          setFilterPriceClass={this.setFilterPriceClass}
          clearFilter={this.clearFilter}
          filterCategory={this.state.filterCategory}
          filterPriceClass={this.state.filterPriceClass}
          removeCategoryValueFromFilter={this.removeCategoryValueFromFilter}
          removePriceClassValueFromFilter={this.removePriceClassValueFromFilter}
        
        />

        {/* // <FilterField 
        //   setView={this.setView} 
        //   sortByDistance={this.sortByDistance} 
        //   sortByPriceClass={this.sortByPriceClass}
        //   getRandomSlug={this.getRandomSlug}
        //   posts={this.state.posts}
        //   categoryOptions={this.getOptionCategories()}
        //   setFilterCategory={this.setFilterCategory}
        //   setFilterPriceClass={this.setFilterPriceClass}
        //   clearFilter={this.clearFilter}
        //   filterCategory={this.state.filterCategory}
        //   filterPriceClass={this.state.filterPriceClass}
        //   removeCategoryValueFromFilter={this.removeCategoryValueFromFilter}
        //   removePriceClassValueFromFilter={this.removePriceClassValueFromFilter}
        // /> */}

        <List 
          posts={this.state.posts} 
          calculateDistance={this.calculateDistance} 
          setView={this.setView}
        />

      </Layout>
    )

  }

}


IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            description
            tags
            categories
            priceClass
            position
            address
            image {
              name
              absolutePath
              publicURL
            }
          }
        }
      }
    }
  }
`