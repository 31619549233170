import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.sass'

const CurrentFilteredValues = class extends React.Component {

    render(){
        return(
            <div className="current-filtered-values__container">

                {this.props.filterCategory ? 
                    this.props.filterCategory
                        .split(", ")
                        .map((category, i) => { 
                            console.log(category);
                            return <div className="current-filtered-values__value" value={category} data-category={category} onClick={this.props.removeCategoryValueFromFilter} key={i}> {category} ✕ </div>
                        })
                    : ''
                }

                {this.props.filterPriceClass ? <div className="current-filtered-values__value" value={this.props.filterPriceClass} onClick={this.props.removePriceClassValueFromFilter}> {this.props.filterPriceClass} ✕ </div> : ''} 

            </div>

        )
    }

}

CurrentFilteredValues.propTypes = {
    filterCategory: PropTypes.string,
    filterPriceClass: PropTypes.string,
    removeCategoryValueFromFilter: PropTypes.func,
    removePriceClassValueFromFilter: PropTypes.func
  }



export default CurrentFilteredValues;