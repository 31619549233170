import React from 'react'
import { Link } from 'gatsby'
import './styles/index.sass'

import Dropdown from '../Dropdown'
import CurrentFilteredValues from '../CurrentFilteredValues'

import addIcon from '../../../static/img/addicon.png'
import mapIcon from '../../../static/img/mapIcon4.png'
import filterIcon from '../../../static/img/filterIcon.png'
import shuffleIcon from '../../../static/img/shuffleIcon.png'
import closeIcon from '../../../static/img/closeIcon.png'

import '../../styles/mixins.sass'

const FilterField = class extends React.Component {

    state = {
        priceClassOptions: [
            { label: "Budget" },
            { label: "Mellan" },
            { label: "Dyrt" }
        ],
        posts: []
    }

    render(){

        return(

            <React.Fragment>

            <div className="filter-field__container">

                <div className="filter-field__icons-container">

                    <a href="/admin/#/collections/blog/new" className="filter-field__icon-button">
                        <img src={addIcon} className="filter-field__add-icon" alt="Add icon"/>
                        <div className="filter-field__link">Lägg till</div>
                    </a>
                    
                    <div className="filter-field__icon-button" onClick={() => this.props.getRandomSlug()}>
                        <img src={shuffleIcon} className="filter-field__shuffle-icon" alt="Shuffle icon"/>
                        <div className="filter-field__link">Slumpa</div>
                    </div>

                    <Link to="maps/" className="filter-field__icon-button">
                            <img src={mapIcon} className="filter-field__map-icon" alt="Map icon"/>
                            <div className="filter-field__link">Karta</div>
                    </Link>
                
                </div>

        







                <div className="filter-field__dropdown-container">

                    {/* <div className="filter-field__clear">
                        {this.props.filterCategory || this.props.filterPriceClass 
                        ? <img src={closeIcon} className="filter-field__clear-icon" alt="Rensa filter-ikon" onClick={this.props.clearFilter} />
                        : ''}
                    </div> */}

                    <img src={filterIcon} className="filter-field__filter-icon" alt="Filter icon"/>
                    {/* <span className="filter-field__text">Filtrera på:</span> */}

                    <span onClick={this.props.sortByDistance} className="filter-field__filter-link">Avstånd</span>


                    <div className="filter-field__dropdown-container-category">

                        <Dropdown 
                            onChange={this.props.setFilterCategory} 
                            options={this.props.categoryOptions} 
                            placeholder="KATEGORI"
                            positionRight="calc(5% + 120px)" 
                            selectedValue={this.props.filterCategory}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />

                    </div>

                    <div className="filter-field__dropdown-container-priceClass">

                        <Dropdown 
                            onChange={this.props.setFilterPriceClass}  
                            options={this.state.priceClassOptions} 
                            placeholder="PRISKLASS"
                            positionRight="5%" 
                            selectedValue={this.props.filterPriceClass}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />

                    </div>


                </div>

            </div>

            <CurrentFilteredValues 
                filterCategory={this.props.filterCategory}
                filterPriceClass={this.props.filterPriceClass}
                removeCategoryValueFromFilter={this.props.removeCategoryValueFromFilter}
                removePriceClassValueFromFilter={this.props.removePriceClassValueFromFilter}
            />
            </React.Fragment>

        )
    }
}


export default FilterField
    