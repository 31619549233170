import React from 'react'
import './styles/index.sass'


  

const NoMatch = () => {

    return(
        <div className="no-match__container">
          Tyvärr, inga matchingar.
        </div>
    )

}


export default NoMatch;